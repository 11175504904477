<template>
  <app-content-layout>
    <app-sidebar :items="menus" />
    <router-view />
  </app-content-layout>
</template>
<script>
  import { mapGetters } from "vuex";
  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description index Component Logic
   * @date 25.07.2020
   */
  export default {
    name: "leave",
    data: () => ({
      menus: [],
      employee: {}
    }),
    computed: {
      ...mapGetters({ isSuperAdmin: "auth/isSuperAdmin" }),
      items() {
        return [
          {
            title: this.$t("leave.menu.leave_accruals"),
            to: { name: "leave_accruals" },
            icon: "mdi-bag-carry-on",
            active: true,
            visible: true,
            access_key: "leave_accruals"
          },
          {
            title: this.$t("leave.menu.leave_requests"),
            to: { name: "leave_requests" },
            icon: "tw-leave-free",
            active: true,
            visible: true,
            access_key: "leave_requests"
          },
          {
            title: this.$t("pendingRequests"),
            to: { name: "leave_approvals" },
            icon: "mdi-checkbox-multiple-marked-circle",
            active: true,
            visible: true,
            access_key: "leaveManager"
          },
          {
            title: this.$t("allPendingRequests"),
            to: { name: "leave_approvals_all" },
            icon: "mdi-checkbox-multiple-marked-circle-outline",
            active: true,
            visible: this.isSuperAdmin,
            access_key: "leaveManager"
          }
        ];
      }
    },
    methods: {
      menuGenerate() {
        this.menus = this.items.filter((item) => {
          if (item.access_key === "leaveManager") {
            return (item.visible = this.$token_manager.get("leaveManager"));
          } else {
            return (item.visible = true);
          }
        });
      }
    },
    created() {
      this.menuGenerate();
    }
  };
</script>
